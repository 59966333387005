<i18n src="@i18n/ticket/ticket.th.yaml" locale="th" lang="yaml"/>
<i18n locale="th" lang="yaml" >
page.title : "Ticket"
page.description : "แสดงและค้นหา Ticket ที่อยู่ในระบบ"

ticket.search.keyword : "ค้นหา"
ticket.search.keyword.placeholder: "ค้นหา"

ticket.field.status.placeholder : "สถานะ Ticket"
ticket.field.problem_type.placeholder : "เลือกรูปแบบ"
ticket.field.ticket_types.placeholder : "ประเภทปัญหา"
ticket.field.company.placeholder : "เลือกศูนย์ที่เปิด Ticket"
ticket.field.is_general_ticket.search : "แสดงเฉพาะปัญหาทั่วไป"
ticket.field.resolved_result.placeholder : "เลือกผลวิเคราะห์"
ticket.company.chart.name : "Ticket ของแต่ละศูนย์บริการ"
ticket.opener.chart.name: "Ticket รับแจ้งโดย"
ticket.status.chart.name: "สถานะ Ticket"
ticket.drone.chart.name: "Drone"

button.create_ticket.general : "สร้าง Ticket ปัญหาทั่วไป"
button.create_ticket.remark : "สำหรับ Ticket สำหรับ Drone เปิดได้จากหน้าข้อมูล Drone เท่านั้น"
</i18n>
<template>
	<div class="page page-padding">
		<my-page-header :title="$t('page.title')" :description="$t('page.description')" />
		<div v-if="canCreateTicket" class="page-action-right">
			<a-button class="btn-page-action" type="primary" icon="plus" @click="clickCreateTicket">
				{{ $t('button.create_ticket.general') }}
			</a-button>
			<div class="button-remark">
				{{ $t('button.create_ticket.remark') }}
			</div>
		</div>

		<div class="myform-action-pane">
			<a-form layout="inline">
				<a-form-item :label="$t('ticket.search.keyword')">
					<a-input v-model="filter.keyword" style="width: 250px" :placeholder="$t('ticket.search.keyword.placeholder')" allow-clear>
						<a-icon slot="suffix" type="search" />
					</a-input>
				</a-form-item>

				<a-form-item :label="$t('ticket.field.problem_type')">
					<a-select
						v-model="filter.problem_type"
						class="ticket-types-select"
						:get-popup-container="getPopupContainer"
						:placeholder="$t('ticket.field.problem_type.placeholder')"
						:options="problemTypeOptions"
						allow-clear />
				</a-form-item>

				<a-form-item :label="$t('ticket.field.status')">
					<a-select
						v-model="filter.status_list"
						mode="multiple"
						class="ticket-status-select"
						:get-popup-container="getPopupContainer"
						:placeholder="$t('ticket.field.status.placeholder')"
						:options="statusOptions"
						allow-clear
						@change="statusSelected" />
				</a-form-item>

				<a-form-item class="myform-expandable-row">
					<ExpanableLink v-model="searchMore" class="search-more-link ant-form-text" :title="$t('common.search.more_options')" />
				</a-form-item>

				<CollapseTransition>
					<div v-show="searchMore" class="myform-expandable-pane">
						<a-form-item :label="$t('ticket.field.ticket_types')">
							<a-select
								v-model="filter.ticket_types"
								mode="multiple"
								class="ticket-types-select"
								:get-popup-container="getPopupContainer"
								:placeholder="$t('ticket.field.ticket_types.placeholder')"
								:options="ticketTypeOptions"
								allow-clear />
						</a-form-item>

						<a-form-item :label="$t('ticket.field.event.date')">
							<MyRangeDatePicker v-model="filter.event_dates" />
						</a-form-item>

						<a-form-item :label="$t('ticket.field.company')">
							<CompanySelect
								v-model="filter.report_by_company_id"
								:placeholder="$t('ticket.field.company.placeholder')"
								allow-clear
								@change="companySelected" />
						</a-form-item>
						<a-form-item :label="$t('ticket.field.resolved_result')">
							<a-select
								v-model="filter.resolved_result"
								class="ticket-types-select"
								:get-popup-container="getPopupContainer"
								:placeholder="$t('ticket.field.resolved_result.placeholder')"
								:options="resultOptions"
								allow-clear />
						</a-form-item>

						<a-form-item>
							<a-checkbox v-model="filter.is_general_ticket">
								{{ $t('ticket.field.is_general_ticket.search') }}
							</a-checkbox>
						</a-form-item>
					</div>
				</CollapseTransition>
			</a-form>
		</div>
		<div class="myform-action-pane active-ticket-chart-panel">
			<div class="active-ticket-chart">
				<TicketsChart
					v-if="companyChartData"
					ref="companyChart"
					:container-width="600"
					:chart-data="companyChartData"
					:total-data="totalCompanyChartData"
					:name="$t('ticket.company.chart.name')"
					@clicked="onClickCompany" />
			</div>
			<div class="active-ticket-chart">
				<TicketsChart
					v-if="openerChartData"
					:container-width="600"
					:chart-data="openerChartData"
					:total-data="totalOpenerChartData"
					:name="$t('ticket.opener.chart.name')"
					@clicked="onClickOpener" />
			</div>
			<div class="active-ticket-chart">
				<TicketsChart
					v-if="statusChartData"
					ref="statusChart"
					:container-width="600"
					:chart-data="statusChartData"
					:total-data="totalStatusChartData"
					:is-enum-required="true"
					:name="$t('ticket.status.chart.name')"
					@clicked="onClickStatus" />
			</div>
			<div class="active-ticket-chart">
				<TicketsChart
					v-if="droneChartData"
					:container-width="600"
					:chart-data="droneChartData"
					:total-data="totalDroneChartData"
					:name="$t('ticket.drone.chart.name')"
					@clicked="onClickDrone" />
			</div>
		</div>
		<a-card :bordered="false">
			<TicketTable
				bordered
				:row-key="(record) => record.id"
				:data-source="ticketDataList"
				:pagination="pagination"
				:loading="loading"
				@change="handleTableChange" />
		</a-card>
	</div>
</template>
<script>
import PageMixin from '@mixins/PageMixin.vue'
import LoginInfoMixin from '@mixins/LoginInfoMixin.vue'
import CompanySelect from '@components/company/CompanySelect.vue'
import ExpanableLink from '@components/common/ExpandableLink.vue'
import MyRangeDatePicker from '@components/input/MyRangeDatePicker.vue'
import TicketTable from '@components/ticket/TicketTable.vue'
import ApiError from '@utils/errors/ApiError'
import { CollapseTransition } from 'vue2-transitions'
import { Checkbox } from 'ant-design-vue'
import axios from 'axios'
import _debounce from 'lodash/debounce'
import { fulfillTicket } from '@utils/objectUtil'
import PopupMixin from '@mixins/PopupMixin.vue'
import { getEnumSelectOptions } from '@utils/formUtil'
import TicketsChart from '@components/chart/TicketsChart.vue'
import { mapGetters, mapState } from 'vuex'
export default {
	page() {
		return {
			title: this.$t('page.title'),
		}
	},
	components: {
		TicketTable,
		CompanySelect,
		TicketsChart,
		ExpanableLink,
		CollapseTransition,
		MyRangeDatePicker,
		'a-checkbox': Checkbox,
	},
	mixins: [PageMixin, PopupMixin, LoginInfoMixin],
	data() {
		return {
			searchMore: false,
			ticketDataList: [],
			companyChartData: null,
			totalCompanyChartData: 0,
			openerChartData: null,
			totalOpenerChartData: 0,
			statusChartData: null,
			totalStatusChartData: 0,
			droneChartData: null,
			totalDroneChartData: 0,

			pagination: {
				pageSize: 10,
				current: 1,
				total: 0,
				hideOnSinglePage: true,
			},
			sort: {
				field: 'updated_datetime',
				order: 'descend',
			},
			filter: {
				drone_id: undefined,
				is_general_ticket: false,
				report_by_company_id: undefined,
				report_by: undefined,
				problem_type: undefined,
				keyword: '',
				resolved_result: undefined,
				status_list: [],
				ticket_types: [],
				event_dates: {},
				drone_model_name: '',
			},
			loading: false,
			activeOnly: true,
		}
	},
	computed: {
		...mapState('user', ['companies']),
		...mapState('auth', ['currentCompany']),
		...mapGetters('user', ['getCompanyById', 'listActiveOnly']),
		canCreateTicket() {
			return this.$authorize('create', 'ticket')
		},
		ticketTypeOptions() {
			const options = ['hardware', 'software', 'part', 'others']
			return getEnumSelectOptions(this, 'ticket.ticket_type', options)
		},
		problemTypeOptions() {
			const options = ['general', 'consult']
			return getEnumSelectOptions(this, 'ticket.problem_type', options)
		},
		statusOptions() {
			const options = ['new', 'reopen', 'investigate', 'resolved', 'pending', 'close', 'cancel']
			return getEnumSelectOptions(this, 'ticket.status', options)
		},
		resultOptions() {
			const options = ['unidentified', 'positive', 'negative']
			return getEnumSelectOptions(this, 'ticket.resolved_result', options)
		},
	},
	watch: {
		filter: {
			handler() {
				this.debounceSearchChange()
			},
			deep: true,
		},
	},
	created() {
		if (this.$notEmpty(this.$route.query.status_list)) {
			const statusList = this.$route.query.status_list.split(',')
			for (const statusCheck of statusList) {
				if (this.statusOptions.findIndex((status) => status.value == statusCheck) >= 0) this.filter.status_list.push(statusCheck)
			}
		} else {
			const defaultOptions = ['new', 'reopen', 'investigate', 'resolved', 'pending']
			this.filter.status_list = defaultOptions
		}
		this.debounceSearchChange = _debounce(this.handleSearchChange, 300)
	},
	mounted() {
		this.fetchData()
		this.fetchCompanyData()
		this.fetchOpenerData()
		this.fetchStatusData()
		this.fetchDroneData()
	},
	methods: {
		handleSearchChange() {
			// Reset Pagination
			this.pagination.current = 1
			this.fetchData()
		},
		fetchData(toPage = null) {
			if (toPage === null) toPage = this.pagination.current
			const search = {
				page_size: this.pagination.pageSize,
				current_page: toPage,
				sort_field: this.sort.field,
				sort_order: this.sort.order,
			}
			this.loading = true
			const filter = { ...this.filter }

			axios
				.get('/api/tickets/search', { params: { search, filter } })
				.then((response) => {
					const page = response.data.data.pagination
					const dataBucket = response.data.data.bucket

					this.ticketDataList = response.data.data.tickets.map((ticket) => {
						return fulfillTicket(ticket, dataBucket)
					})

					this.pagination.current = toPage
					this.pagination.total = page.total
				})
				.catch((error) => {
					this.$message.error(ApiError.getDisplayErrorMessage(this, error))
				})
				.finally(() => {
					this.loading = false
				})
		},
		fetchCompanyData() {
			axios
				.get('/api/tickets/get-ticket-data-by-company')
				.then((response) => {
					this.companyChartData = response.data.data.dataset
					this.totalCompanyChartData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		fetchOpenerData() {
			axios
				.get('/api/tickets/get-ticket-data-by-opener')
				.then((response) => {
					this.openerChartData = response.data.data.dataset
					this.totalOpenerChartData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		fetchStatusData() {
			axios
				.get('/api/tickets/get-ticket-data-by-status')
				.then((response) => {
					this.statusChartData = response.data.data.dataset
					this.totalStatusChartData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		fetchDroneData() {
			axios
				.get('/api/tickets/get-ticket-data-by-drone-name')
				.then((response) => {
					this.droneChartData = response.data.data.dataset
					this.totalDroneChartData = response.data.data.total
				})
				.catch((error) => {
					this.error = ApiError.getDisplayErrorMessage(this, error)
				})
		},
		handleTableChange(pagination, filters, sorter) {
			this.sort.field = sorter.order === undefined ? null : sorter.columnKey
			this.sort.order = sorter.order
			this.fetchData(pagination.current)
		},
		clickCreateTicket() {
			this.$router.push({ name: 'ticket/create_general' })
		},
		onClickCompany(name) {
			if (name === undefined) {
				this.filter.report_by_company_id = undefined
			} else {
				const company = this.companies.filter((c) => {
					if (this.activeOnly && c.company_status != 'active') return false
					return c.name === name
				})
				this.filter.report_by_company_id = company[0].id
			}
		},
		onClickStatus(status) {
			const options = ['new', 'reopen', 'investigate', 'resolved', 'pending']
			if (status === undefined) {
				this.filter.status_list = options
			} else {
				const selectedOption = options.find((items) => this.$tenum('ticket.status', items) === status)
				this.filter.status_list = [selectedOption]
			}
		},
		onClickOpener(name) {
			this.filter.report_by = name
		},
		onClickDrone(name) {
			this.filter.drone_model_name = name
		},
		companySelected() {
			this.$refs.companyChart.resetChart()
		},
		statusSelected() {
			this.$refs.statusChart.resetChart()
		},
	},
}
</script>
<style lang="less" scoped>
.ticket-types-select {
	min-width: 150px;
}
.button-remark {
	color: @text-muted;
	font-size: 0.8em;
	margin-top: -8px;
	margin-bottom: 16px;
}
.active-ticket-chart {
	width: 25%;
}
.active-ticket-chart-panel {
	display: flex;
	flex-direction: 'row';
	justify-content: 'space-between';
	margin-left: 'auto';
	margin-right: 'auto';
}
.ticket-status-select {
	min-width: 250px;
	max-width: 550px;
}
</style>
